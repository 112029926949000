<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <!-- Header -->
    <div class="flex justify-between items-center border-b border-gray-200 pb-8">
      <h3 class="text-base md:text-24 leading-tight font-bold font-heading text-gray-800">Rss Feed example</h3>

      <!-- Action buttons -->
    </div>

    <div class="w-full flex pt-16 flex-1 flex-col overflow-y-auto pb-8 md:pb-40">
      <div class="w-full h-full overflow-y-auto flex flex-col">
        <p class="font-medium text-left mb-4 text-blue-600 whitespace-normal text-18">Get feed example</p>
        <div class="w-full mt-10 px-6">
          <Form
            :validation-schema="rssFeedUrlSchema"
            autocomplete="off"
            @submit="onSubmitForm"
          >
            <AppInput
              id="rssFeedUrl"
              v-model="rssFeedUrl"
              type="text"
              name="rssFeedUrl"
              placeholder="https://..."
              label="RSS Feed Url"
              autofocus
              required
            />

            <AppButton
              :loading="loading"
              variant="primary"
              type="submit"
              class="w-full"
            >
              Submit
            </AppButton>
          </Form>
        </div>

        <div class="w-full h-full mt-10 overflow-y-auto">
          <pre class="text-10">{{ rssFeedResult }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { Form } from 'vee-validate'

import { routeNames } from '@/router'
import { notification } from '@/core/helpers'
import { rssFeedUrlSchema } from '@/core/schemas'
import { rssResponseService } from '@/services'

import AppButton from '@/components/stateless/AppButton.vue'
import AppInput from '@/components/stateless/AppInput.vue'

export default defineComponent({
  name: 'RssResponse',

  components: {
    Form,
    AppButton,
    AppInput
  },

  setup () {
    const loading = ref(false)
    const rssFeedUrl = ref(null)
    const rssFeedResult = ref(null)

    const onSubmitForm = async () => {
      loading.value = true

      try {
        const result = await rssResponseService.get(rssFeedUrl.value)

        rssFeedResult.value = result
      } catch (err) {
        notification({ title: 'Invalid RSS Feed url!', errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }

    return {
      routeNames,
      loading,
      rssFeedUrl,
      onSubmitForm,
      rssFeedUrlSchema,
      rssFeedResult
    }
  }
})
</script>
